import React from 'react'
import ListGroup from 'react-bootstrap/ListGroup'

export const QueryParamsItem = ({ queryParamKey, queryParamValue }) => {
  return (
    <ListGroup.Item>
      {queryParamKey} = {queryParamValue}
    </ListGroup.Item>
  )
}
