import React from 'react'
import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'
import Badge from 'react-bootstrap/Badge'
import styled from 'styled-components'

const BadgeWithNumber = styled(Badge)`
  &:after {
    content: '${(props) => props.stepNumber}';
    position: absolute;
    left: 1px;
    top: 1px;
    border: 1px solid #17a2b8;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: auto;
    line-height: 1.5;
    background: #17a2b8;
    color: #fff;
    font-weight: 300;
  }
`

export const StepTop = ({
  hasNext,
  url,
  hasQueryParams,
  hasCookiesData,
  accordionId,
  responseStatus,
}) => {
  return (
    <Accordion.Toggle as={Card.Header} eventKey={accordionId}>
      <BadgeWithNumber
        variant={hasNext ? 'warning' : 'success'}
        stepNumber={accordionId + 1}
      >
        {responseStatus}
      </BadgeWithNumber>{' '}
      {hasQueryParams && <span>🔑</span>} {hasCookiesData && <span>🍪</span>}{' '}
      <span style={{ fontFamily: 'Source Code Pro', fontSize: '0.8em' }}>
        {url}
      </span>
    </Accordion.Toggle>
  )
}
