import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import { CookiesItem } from '../cookiesItem/CookiesItem'

export const CookiesList = ({ cookiesData, accordionId }) => {
  return (
    <Accordion.Collapse eventKey={accordionId}>
      <Card.Body>
        <h5>Cookies:</h5>
        <ListGroup>
          {cookiesData.map((c, i) => (
            <CookiesItem key={i} cookieString={c} />
          ))}
        </ListGroup>
      </Card.Body>
    </Accordion.Collapse>
  )
}
