import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import * as logo from '../../logo.svg'

export const Header = () => {
  return (
    <Navbar expand='md' variant='light' bg='light'>
      <Container>
        <Navbar.Brand href='/'>
          <img src={logo} style={{ width: '100px' }} />
        </Navbar.Brand>
        <Nav className='justify-content-end' activeKey='/'>
          <Nav.Link href='https://johnnykramer.dev' target='_blank'>
            Developed by @johnnykramer
          </Nav.Link>
          <Nav.Link href='https://pozitiff.cz/' target='_blank'>
            Powered by Pozitiff
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  )
}
