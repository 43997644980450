import React from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

export const RequestForm = ({
  isLoading,
  submitForm,
  handleChangeUrl,
  url,
}) => {
  return (
    <Form onSubmit={!isLoading ? submitForm : null}>
      <InputGroup className='mb-3'>
        <FormControl
          placeholder='https://'
          autoFocus
          onChange={(e) => handleChangeUrl(e.currentTarget.value)}
          value={url}
        />
        <InputGroup.Append>
          <Button type='submit' variant='info' disabled={isLoading}>
            {!isLoading ? (
              'GO'
            ) : (
              <Spinner animation='border' size='sm' variant='light' />
            )}
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </Form>
  )
}
