import React from 'react'
import Card from 'react-bootstrap/Card'

export const Instructions = () => (
  <Card>
    <Card.Body>
      <Card.Title>How to use?</Card.Title>
      <Card.Text>
        <ol>
          <li>Put URL to the field above</li>
          <li>Click "GO"</li>
          <li>...</li>
          <li>Enjoy!</li>
        </ol>
        <p>
          Any redirect result can have an icons, that means result is clickable
          to see more structured info.
        </p>
        <ul style={{ listStyle: 'none' }}>
          <li>🍪 — cookies</li>
          <li>🔑 — query params</li>
        </ul>
      </Card.Text>
    </Card.Body>
  </Card>
)
