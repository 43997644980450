import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import { QueryParamsItem } from '../queryParamsItem/QueryParamsItem'

export const QueryParamsList = ({ queryParamsData, accordionId }) => {
  return (
    <Accordion.Collapse eventKey={accordionId}>
      <Card.Body>
        <h5>Query params:</h5>
        <ListGroup>
          {queryParamsData.map(({ key, value }, i) => (
            <QueryParamsItem
              key={i}
              queryParamKey={key}
              queryParamValue={value}
            />
          ))}
        </ListGroup>
      </Card.Body>
    </Accordion.Collapse>
  )
}
